import React from "react";

const Results = () => {
  return (
    <div>
      <div className="result">Result</div>
    </div>
  );
};

export default Results;
